.App {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;

}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 12px;
}

.header div {
  cursor: pointer;
}

.dark {
  background-color: #282c34;
  color: white;
}

.dark input[type="text"] {
  color: white;
}





.movie-list {
  display: flex;
  cursor: pointer;
  margin-top: 8px;
  user-select: none;
}

.director-list {
  display: flex;
  cursor: pointer;
  margin-bottom: 24px;
  user-select: none;
}

.director-name {
  cursor: pointer;
  user-select: none;
}

.watched-text {
  margin-bottom: 8px;
}

.list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

input[type="checkbox"] {
  margin-right: 12px;  
}

input[type="text"] {
  border-width: 0px;
  text-align: left;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding: 20px 0px;
  outline-width: 0;
  width: 100%;
  background-color: transparent;
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  
}

@media (max-width: 768px) {
  .App {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
  }
  h2 {
    font-size: 20px;
  }
  div {
    font-size: 12px;
  }
} 

.submit-button {
  border-radius: 4px;
  border-width: 0px;
  color: white;
  cursor: pointer;
  user-select: none;
}